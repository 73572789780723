.default-theme .learning {
  &__wrapper {
    height: calc(100vh - 152px);
    padding-right: 4px;
  }

  &__page-box {
    overflow-y: hidden;
    display: block;
    height: 100%;
    padding: 30px 26px 30px 30px;

    @media screen and (height <= 620px) and (width >= 980px) {
      overflow-y: auto;
    }
  }

  &__page {
    display: flex;
    gap: 150px;
    justify-content: center;

    height: 100%;
    min-height: 440px;

    @media screen and (width >= 1440px) and (height >= 720px) {
      min-height: 520px;
    }

    @media screen and (width >= 1600px) and (height >= 775px) {
      gap: 200px;
      min-height: 590px;
    }
  }

  &__card {
    will-change: transform;

    position: relative;

    overflow: hidden;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    width: 446px;
    height: 490px;
    min-height: 440px;
    padding: 19px 20px 20px;

    border-radius: 10px;

    // позже надо разобраться с тенями (mat-elevation)
    box-shadow: 1px 1px 2px rgb(0 0 0 / 40%) !important;

    @media screen and (width >= 1440px) and (height >= 720px) {
      width: 500px;
      height: 590px;
      min-height: 520px;
      padding: 24px 20px 20px;
    }

    @media screen and (width >= 1600px) and (height >= 775px) {
      width: 554px;
      height: 670px;
      min-height: 590px;
    }

    @media screen and (width >= 1920px) and (height >= 775px) {
      width: 600px;
    }
  }

  &__card-loader {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }

  &__btn {
    flex-shrink: 0;

    width: 100%;
    height: 46px;
    padding: 0;

    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: var(--global-white-color);

    border-radius: 5px;

    @media (width >= 1600px) and (height >= 775px) {
      height: 56px;
      font-size: 18px;
    }
  }

  &__card-title {
    &--player {
      margin-bottom: 69px;

      @media screen and (width >= 1440px) and (height >= 720px) {
        margin-bottom: 94px;
      }

      @media screen and (width >= 1600px) and (height >= 775px) {
        margin-bottom: 84px;
      }
    }

    &--writing {
      margin-bottom: 37px;

      @media screen and (width >= 1440px) and (height >= 720px) {
        margin-bottom: 42px;
      }

      @media screen and (width >= 1600px) and (height >= 775px) {
        margin-bottom: 43px;
      }
    }

    &--writing_view {
      margin-bottom: 99px;

      @media screen and (width >= 1440px) and (height >= 720px) {
        margin-bottom: 129px;
      }
    }

    &--cards {
      margin-bottom: auto;
    }

    &--cards_view {
      margin-bottom: 86px;

      @media screen and (width >= 1440px) and (height >= 720px) {
        margin-bottom: 111px;
      }
    }
  }

  &__control-btn-box {
    flex-shrink: 0;
    align-self: center;
    width: 40px;
    height: 40px;

    @media screen and (width >= 1600px) {
      width: 50px;
      height: 50px;
    }
  }

  &__control-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }

  &__control-icon {
    display: block;
    width: 9px;
    height: 18px;
    line-height: 1;

    &--prev {
      margin-right: 2px;
    }

    &--next {
      margin-left: 2px;
    }

    @media screen and (width >= 1600px) and (height >= 775px) {
      width: 12px;
      height: 20px;
    }
  }
}

.default-theme .result {
  pointer-events: none;

  position: absolute;
  z-index: 999;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 56px;
  color: white;

  &__icon-box {
    display: flex;
    flex-direction: column;
    gap: 19px;
    align-items: center;

    width: 136px;
  }

  &__icon {
    width: 114px;
    height: 114px;
  }

  &__text {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    color: #40b34f;
  }
}
