@use '@angular/material' as mat;

@import '@angular/material/theming';

@mixin file-uploader-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .file-input {
    background: mat.get-color-from-palette($background, card);

    & > .button {
      background: var(--global-light-gray-color);
    }

    & > .label {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }

    &:hover > .button {
      color: white;
      background: mat.get-color-from-palette($accent, 500);
    }
  }
}
