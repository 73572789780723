:root {
  // Цвета которые можно обозначить как primary, accent и warn;
  --global-primary-color: #0f2c6e;
  --global-accent-color: #40b34f;
  --global-warn-color: #ff7070;

  // базовые цвета.
  --global-base-black-color: #000;
  --global-white-color: #fff;

  // цвета преимущественно для текста.
  --global-black-color: #212121;
  --global-gray-color: #757575;
  --global-dark-gray-color: #b6b6b6;
  --global-mid-gray-color: #dadada;
  --global-light-gray-color: #f3f3f3;

  // оттенки синего
  --global-dark-blue-color: #8798ad;
  --global-mid-blue-color: #cdd8e7;
  --global-light-blue-color: #e2e5ee;

  // остальные цвета
  --global-blue-color: #1ca5fc;
  --global-orange-color: #fda729;
  --global-yellow-color: #fac000;
}
