.zero-padding-container .mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 0;
}

.rounded-5 .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 5px;
}

.rounded-10 .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 10px;
}

.rounded-15 .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 15px;
}

.rounded-20 .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 20px;
}
