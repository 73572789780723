// данный класс будет применяться только для form-field с input внутри. Для textarea скорее всего определим в другом месте.

.default-theme .lt-textfield {
  // Эти переменные используются материалом. Присваиваем наши цвета.
  --mdc-theme-error: #ff7070;
  --mdc-theme-primary: #0f2c70;

  // под вопросом. Читай ниже где задается определение цвета для border-color;
  --mdc-theme-secondary: #40b34f;

  // Зададим пока тут по умолчанию, так как везде примерно такой line-height
  input {
    line-height: 28px;
  }

  // непосредственно компонент mat-form-field
  &.mat-mdc-form-field {
    width: 100%;
    color: #b6b6b6;
  }

  // обрати внимание на эти стили.
  &.mat-focused:not(.mat-form-field-invalid) {
    color: var(--mdc-theme-primary);
  }

  &.mat-form-field-invalid {
    color: var(--mdc-theme-error);
  }

  // стили для текста ошибки или подсказки.
  .mat-mdc-form-field-subscript-wrapper {
    font-size: 12px;
    line-height: 16px;
  }

  &.mat-mdc-form-field.mat-form-field-appearance-outline {
    .mdc-text-field {
      // Задаем стили для mdc-notched-outline (outline у поля)
      .mdc-notched-outline > * {
        /* Это скорее всего временное решение, потому что по умолчанию material берет цвета из тем и оперировать нужно состоянием FormControl. Так как пока темы не настроены, и для переработки некоторых мест нужно больше времени пока "проще" менять color.
        */
        // ! чтобы задать цвет для outline, достаточно поменять color у mat-form-field.
        border-color: currentcolor;

        // везде по умолчанию (дизайн макет) такая толщина. Если где-то отличается, нужно перезаписать локально в компоненте.
        border-width: 1px;
      }

      .mdc-notched-outline__notch {
        // width: fit-content !important;

        // padding-right: 8px; // не помню для чего этот тут.
        padding-right: 0;

        // возвращаем фикс из material. important нужен чтобы не писать все правило из исходников. (там не лучше написано)
        border-left: 1px solid transparent !important;
      }

      .mdc-notched-outline__leading {
        width: max(16px, var(--mdc-shape-small, 4px));
      }
    }

    .mat-mdc-form-field-infix {
      height: 100%;

      // на макете есть вариант где эти цифры иные, если поле ввода крупнее. Прямо сейчас нигде на проекте (кроме админки, но там нет макета в принципе) нет более "высокого" поля ввода. В основном все по 48px. В будущем надо будет подумать. Либо использовать container queries, либо задать отдельно модификатор.
      min-height: 48px;
      padding: 10px 0;
    }

    // убираем изменение цвета при наведение.
    .mdc-text-field:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline > * {
      border-color: currentcolor;
    }

    // в процессе.
    .mdc-text-field--focused:not(.mdc-text-field--disabled, .mdc-text-field--invalid) {
      // color: var(--mdc-theme-primary);
      color: currentcolor;
    }

    // LABEL
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 22px;
      font-size: 16px;
      color: currentcolor;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
      --mat-mdc-form-field-label-transform: translateY(-30.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));

      transform: var(--mat-mdc-form-field-label-transform);
    }
  }
}
