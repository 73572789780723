.spinner {
  display: inline-block;

  box-sizing: border-box;
  width: 44px;
  height: 44px;

  border: 5px solid #40b34f;
  border-bottom-color: transparent;
  border-radius: 50%;

  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
