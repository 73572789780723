@use '@angular/material' as mat;

@import '@angular/material/theming';

@mixin searchBar-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .search-bar {
    .mat-mdc-input-element {
      color: mat.get-color-from-palette($foreground, secondary-text);

      &::placeholder {
        color: mat.get-color-from-palette($foreground, secondary-text);
      }
    }

    .mat-icon {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }

    &.mat-focused {
      .mat-icon {
        color: inherit;
      }

      .mat-mdc-input-element {
        color: inherit;
      }
    }
  }
}
