@use '@angular/material' as mat;

@import '@angular/material/theming';

@mixin navigationPage-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .navigation-page {
    background-color: mat.get-color-from-palette($background, card);

    h1 {
      color: mat.get-color-from-palette($primary, 500);
    }
  }
}
