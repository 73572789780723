@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  src: url('/assets/fonts/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  src: url('/assets/fonts/IBMPlexSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'IBM PLex Sans';
  font-weight: 600;
  src: url('/assets/fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
}
