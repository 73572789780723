// контейнер для основной части приложения.
.container {
  max-width: 1920px;
  margin: 0 auto !important;
  padding: 0 30px !important;
}

// ToDo: посмотреть потом эти стили.
.home-page,
.admin-page {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 54px - 48px - 48px - 8px);

  // width: 1920px;
  margin: auto;
  padding: 30px;

  // оставляем место для скроллбара и паддинга обертки:
  padding-right: 20px;
}

// пока нужен important, позже уберем.
.landing-container {
  max-width: 1280px;
  margin: 0 auto !important;
  padding: 0 30px !important;

  @media screen and (width <= 1279px) {
    padding: 0 20px !important;
  }
}
