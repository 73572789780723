.flag {
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
  }

  // select-trigger
  &--small {
    width: 20px;
    height: 20px;
  }

  // for mat-option
  &--medium {
    width: 24px;
    height: 24px;
  }

  // for title bar
  &--big {
    width: 30px;
    height: 30px;
  }

  // course preview
  &--large {
    width: 56px;
    height: 56px;
  }
}
