.default-theme .lt-table {
  .table {
    table-layout: fixed;

    &__header {
      height: 56px;
    }

    &__language {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    &__toggle {
      padding-left: 28px;
    }

    &__cell {
      padding: 0 10px;
      color: var(--global-black-color);

      &--collapsed {
        width: 64px;
      }
    }

    &__header-cell {
      // задание ширины не работает в таблице. В будущем просто сделаем кастомную таблицу.
      width: 250px;
      padding: 0 10px;

      font-family: inherit;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: var(--global-gray-color);

      &--collapsed {
        // flex: 0 1 64px;
        width: 64px;
      }
    }

    &__cell:first-child,
    &__header-cell:first-child {
      padding-left: 24px;
    }

    &__cell:last-child,
    &__header-cell:last-child {
      padding-right: 24px;
    }

    &__not-found {
      font-size: 24px;
      text-align: center;
    }

    &__btn {
      width: 44px;
      height: 44px;
      padding: 0;
    }
  }

  .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
    background-color: transparent;
  }
}
