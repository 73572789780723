.default-theme .lt-select {
  // позднее разобраться. Эти цвета должны браться из тем. Плюс для label и arrow, берутся другие почему-то.
  // пока оставляю, эта переопределение влияет на цвет подчеркивания.
  --mdc-theme-primary: #40b34f;
  --mdc-filled-text-field-focus-active-indicator-color: #40b34f;

  height: 100%;

  // (убираем задний фон, но это не подойдет для загрузки курса, так как там он нужен)
  &--no-fill {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: #fff;
    }
  }

  &--no-label {
    /* stylelint-disable-next-line selector-not-notation */
    .mdc-text-field--no-label:not(.mdc-text-field--outlined, .mdc-text-field--textarea):not(.mdc-text-field--textarea)
      .mat-mdc-form-field-infix {
      display: flex;
      align-items: center;
      padding: 0;
    }
  }

  .mdc-text-field {
    padding-left: 12px;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .mat-mdc-form-field-flex {
    height: 100%;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    min-height: 48px;
    padding-top: 20px;
  }

  //  Arrow
  &.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
    transform: rotate(180deg);
    color: var(--mdc-theme-primary);
    transition: transform ease;
  }

  &.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    color: var(--mdc-theme-primary);
  }

  // Label
  &.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
    // без этого label "мыльный"
    will-change: auto;

    top: 24px;

    font-size: 14px;
    line-height: 18px;
    color: #757575;
  }

  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-21px) scale(0.86);
  }

  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--mdc-theme-primary);
  }

  // Underline
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: transparent !important;
  }

  // mat-select
  mat-select-trigger,
  .mat-mdc-select-value-text {
    display: flex;
    gap: 8px;
    align-items: center;

    margin-left: 1px;

    font-size: 14px;
    line-height: 18px;
    color: #212121;
  }
}

// задаем класс для выпадающего списка.
.lt-select-panel-class {
  &::-webkit-scrollbar {
    display: none;
  }

  &.mdc-menu-surface.mat-mdc-select-panel {
    padding: 0;
  }

  .mat-mdc-option {
    padding: 0 16px;
    background: #fff !important;

    &:hover {
      background: rgb(0 0 0 / 5%) !important;
    }

    &:focus-visible {
      outline: 2px solid orange;
      outline-offset: -2px;
    }

    .mdc-list-item__primary-text {
      display: flex;
      gap: 16px;
      align-items: center;

      font-size: 16px;
      line-height: 28px;
      color: #212121 !important;
      word-break: break-word;
      white-space: pre-line;
    }

    mat-pseudo-checkbox {
      display: none;
    }

    .not-selected {
      padding-left: 40px;
    }
  }
}
