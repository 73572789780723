@import '../assets/fonts/IBMPlexSans';

body {
  font-family: 'IBM Plex Sans', sans-serif;
}

input,
button,
a {
  font-family: inherit;
}

a {
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
