.mdc-switch__icons {
  display: none;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple {
  width: 40px !important;
  height: 40px !important;
}

.mdc-switch__track {
  --mdc-switch-selected-track-color: #40b34f8a;
  --mdc-switch-selected-hover-track-color: #40b34f8a;
  --mdc-switch-selected-pressed-track-color: #40b34f8a;
  --mdc-switch-selected-focus-track-color: #40b34f8a;
  --mdc-switch-unselected-track-color: #00000061;
  --mdc-switch-unselected-hover-track-color: #00000061;
  --mdc-switch-unselected-pressed-track-color: #00000061;
  --mdc-switch-unselected-focus-track-color: #00000061;
  --mdc-switch-disabled-selected-track-color: #00000061;
  --mdc-switch-disabled-unselected-track-color: #00000061;
}

.mdc-switch__handle {
  --mdc-switch-selected-handle-color: #40b34f;
  --mdc-switch-selected-hover-handle-color: #40b34f;
  --mdc-switch-selected-pressed-handle-color: #40b34f;
  --mdc-switch-selected-focus-handle-color: #40b34f;
  --mdc-switch-unselected-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-disabled-selected-handle-color: #fafafa;
  --mdc-switch-disabled-unselected-handle-color: #fafafa;
}
