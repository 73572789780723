.scrollbar {
  // color for firefox
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-color: rgb(0 0 0 / 30%) rgb(0 0 0 / 9%);
  }

  // color for chrome, edge
  &::-webkit-scrollbar-track {
    background: rgb(0 0 0 / 9%);
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(0 0 0 / 30%);
  }

  &--thin {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-clip: content-box;
      border-radius: 10px;
    }
  }

  &--wide {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-clip: content-box;
      border-radius: 10px;
    }
  }

  &--without-track {
    @supports not selector(::-webkit-scrollbar) {
      scrollbar-color: rgb(0 0 0 / 30%) transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(0 0 0 / 30%);
    }
  }
}
